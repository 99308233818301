body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main {
  margin-top: 20px;
  display: grid;
  width: 100%;
  /* max-width: 800px; */
  grid-template-columns: 20%  190px auto auto auto 20%;
  grid-template-rows: 110px auto auto 100px;
  grid-template-areas:  "space title title title title space2"
                        "space nav cont1 cont1 cont1 space2"
                        "space cont2 cont2 cont2 cont2 space2"
                        "space footer footer footer footer space2";
}
@media only screen and (max-width: 992px) {
  .main {
    grid-template-columns: 2% 190px auto auto auto 2%;
  }
}

.toplogo  {
  /* justify-content:stretch; */
  margin-top: -20px;
  margin-left: 20px;
  grid-area: title;
}

.toplogo img {
  height: 100%;
}

.navigation {
  grid-area: nav;
  width: 100%;
}

.content-top {
  grid-area: cont1;
	color: #666666;
}

.content-bot {
  margin-left: 12px;
	color: #666666;

  grid-area: cont2;
}

.foot {
  grid-area: footer;
  margin-bottom: 30px;
	color: #666666;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: 100px;
}

.navigation table {
  border-spacing: 0px;
}
.sectitle {
  font-size: 18px;
	color: #990000
}

.articletitle {
  color:#990000;
	margin-top: 25px;
	font-style: italic;
	font-size: 1em
}

.articleauthor{
	margin-top: 10px;
	margin-left: 10px;
  font-size: .9em;
}

.articlelink {
	margin-top: 10px;
  font-size: .9em;
}


a {
	color: #990000;
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

ul {
	font-family: Verdana, Arial, Helvetica, sans-serif;
	font-size: 1em;
	color: #666666;
	/* line-height: 14px; */
	text-align: left;
}

ol {
	font-family: Verdana, Arial, Helvetica, sans-serif;
	/* font-size: 11px; */
	color: #666666;
	/* line-height: 14px; */
	text-align: left;
}

li {
	font-family: Verdana, Arial, Helvetica, sans-serif;
	font-size: 15px;
	color: #666666;
	line-height: 22px; 
	text-align: left;
  margin-top: 10px;
  width:70%;
}


H1 {
	font-family: Verdana, Arial, Helvetica, sans-serif;
	font-size: 22px;
	color: #666666;
}

H2 {
	font-family: Verdana, Arial, Helvetica, sans-serif;
	font-size: 14px;
	color: #666666;
}

H3 {
	font-family: Verdana, Arial, Helvetica, sans-serif;
	font-size: 11px;
	color: #666666;
}

.resource {
  display: grid;
  grid-template-columns: 2fr 5fr;
  align-items: center;
  justify-items: center;
}

.phone {
  width:70%;
}

.sectitle {
	color: #943737
}