.navigation  img:last-child {
    display: none;  
}

.navigation  img:first-child {
    display: block;  
}

.navigation .about:hover img:last-child {
    display: block;  
    animation: fadeIn 2s;
}
.navigation .about:hover img:first-child {
    display: none;  
    animation: fadeIn 2s;
}

.navigation .services:hover img:last-child {
    display: block;  
}
.navigation .services:hover img:first-child {
    display: none;  
}
.navigation .partnering:hover img:last-child {
    display: block;  
}
.navigation .partnering:hover img:first-child {
    display: none;  
}
.navigation .resources:hover img:last-child {
    display: block;  
}
.navigation .resources:hover img:first-child {
    display: none;  
}
.navigation .news:hover img:last-child {
    display: block;  
}
.navigation .news:hover img:first-child {
    display: none;  
}
.navigation .careers:hover img:last-child {
    display: block;  
}
.navigation .careers:hover img:first-child {
    display: none;  
}
.navigation .contact:hover img:last-child {
    display: block;  
}
.navigation .contact:hover img:first-child {
    display: none;  
}